import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalVariable } from './global';
import { MainFile } from './main';
// import { StarPRNT } from '@ionic-native/star-prnt/ngx';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';
// import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
// import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
// import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { GlobalModelComponent } from './global-model/global-model.component';
import { PhotomodalPage } from './photomodal/photomodal.page';
import { AddNewEmailPage } from './add-new-email/add-new-email.page';
import { CommonModule } from '@angular/common';
import { UpdateEmailModalPage } from './update-email-modal/update-email-modal.page';
@NgModule({
  declarations: [AppComponent,GlobalModelComponent,PhotomodalPage,AddNewEmailPage,UpdateEmailModalPage],
  entryComponents: [],
  imports: [BrowserModule,
    HttpClientModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
     IonicModule.forRoot(),
     CommonModule,
      AppRoutingModule],
  providers: [
    Camera,
    GlobalVariable,
    AndroidPermissions,
    MainFile,
    BluetoothSerial,
    // StarPRNT,
    NativeStorage,
    StatusBar,
    NativeAudio,
    // OneSignal,
    Vibration,
    InAppBrowser,
    Stripe,
    BarcodeScanner,
    // LottieSplashScreen,
    BackgroundMode,
    CallNumber,
    ScreenOrientation,
    // FormBuilder,FormGroup,Validators,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
