import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ServerService } from '../providers/server.service';

@Component({
  selector: 'app-update-email-modal',
  templateUrl: './update-email-modal.page.html',
  styleUrls: ['./update-email-modal.page.scss'],
})
export class UpdateEmailModalPage implements OnInit {
  businessId: string;
  email: string;
  id: string;
  from: string;
  type: string;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private http: HttpClient,
    private server :ServerService
  ) {
     const emailData = this.navParams.get('data');
    this.businessId = emailData.business_id;
    this.email = emailData.email;
    this.id = emailData.id;
    this.from = 'app'; // Default value
    this.type = emailData.type;
  }
  ngOnInit(){
    
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  submit() {
    if (!this.email || !this.type) {
      // Handle validation
      alert('Please fill all required fields.');
      return;
    }
console.log(this.email,this.type);
     this.server.updateEmail(this.businessId, this.email, this.type, this.id, this.from).subscribe(response => {
      console.log('Email updated successfully', response);
      this.dismiss();
    }, error => {
      console.error('Error updating email', error);
    });
  }

}

