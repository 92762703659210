import { Injectable } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

@Injectable()
export class GlobalVariable {
  inProduction = 'Testing';
  // baseUrl: string="https://mknxapi.com/api/index.php/"
  // baseUrl: string = 'https://dev-v1.mknxapi.com/api/index.php/'; //testing
  baseUrl: string = 'https://dev.mknxapi.com/api/index.php/'; //production
  // baseUrl: string= 'https://staging.onlineordering.mikronexus.com/index.php/'
  // 'https://staging.onlineordering.mikronexus.com/index.php/'
    globalname:any;
    nick_name:any
    last_name:any;
    street: any;
    city: any;
    state: any;
    zip: any;
    phone:any;
    alternate_phone:any;
    email:any;
    emergency_contact_phone:any;
    emergency_contact_name: any;
    dob:any;
    citizen:any;
    image:any;
    driving_license:string;
    file1:string;
  file2:string;
  file3:string;
  file4:string;
  file5:string;
  social_security_card:string= '';
  business_license1:string;
  business_license2:string;
  business_license3:string;
    driving_license_expiration:any;
    business_license1_expiration:any;
    business_license2_expiration:any;
    business_license3_expiration:any;
    description: any;
    id : any;
    desc_id:any;
    password:string;
    staff_id:any;
  user_type: any;
  username: any;
  name = 'test';
  permissons: any;
  isDataLoaded = false;
  point_system: any;
  logo: any;
  staff_name: any;
  rest_id: any;
  busniess_id: any;
  delivery_time: any;
  pickup_time: any;
  deliveryTiming: any;
  printerTYPE = 'regular';
  prev_printer: any;
  address_printer: any;
  printerDevice: any;
  noPrinter: boolean = true;
  autoPrint = 'false';
  copiesToprint: number = 1;
  prepTime: number = 0;
  alertType = 'standard';
  isLoading = false;
  alertSound = 'sound1';
  orders = [];
  deviceId: string;
  order_count: number = 0;
  ordersPrinted = [];
  check: boolean = true;
  continuousRing = 'false';
  deliverTime = 0;
  userPoints: any = 0;
  manualPrintOrder: boolean = false;
  categoryId: any;
  categoryName: any;
  categoryImage: any;
  categoryPriority: any;
  categoryModifier: any;
  categoryBadgeText: any;
  categoryBadgeColor: any;
  categoryAppEnabled: String;
  categoryWebEnabled: String;
  categoryPosEnabled: String;
  ready_menu: any;
  pendingOrders = [];
  posReports: boolean = false;
  printedMessage: any = [];
  chatEnable: any;
  phoneNumber: any;
  usePaymentsOnly = localStorage.getItem('usePaymentsOnly');
  messageBaseUrl: any = 'https://sms.mikronexus.net';
  // messageBaseUrl : any = 'https://tio-reports.com/'; //updated
  portalUrl =
    'https://onlineordering.mikronexus.com/online-ordering-new/index.php/Business_controller/';
  // password: string;
  // pageName: any;
  actionButtonsToggle = true;
  ordersInterval: any;
  // advance settings
  advanceSettings = {
    username: 'mknx',
    password: 'Musa123@@',
  };
  AScancelledOrders = true;
  ASPendingOrders = true;
  ASadjustedOrders = true;
  ASMenu = true;
  ASStockout = true;
  ASReports = true;
  ASPOSReports = true;
  ASBranches = true;
  ASKitchenDisplay = true;
  ASChat = true;
  ASLoyalty = true;
  printNextMessage: boolean = true;
  superCategories = [];
  superCategoriesChowly = [];
  constructor(
    private toastController: ToastController,
    private androidP: AndroidPermissions,
    private platform: Platform,
    public loadingController: LoadingController,
    private nativeStorage: NativeStorage
  ) {
    // this.portalUrl=this.baseUrl+'business_controller/business_login_via_app?username='+this.username+'&password='+this.password+'&page='+this.pageName;
    // console.log('staging url ',this.baseUrl)
  }
  async showToast(msg, color?: string, duration?: number, position?) {
    duration = 1000;
    const toast = await this.toastController.create({
      message: msg,
      color: color ? color : 'primary',
      duration: duration ? duration : 2000,
      position: position ? position : 'bottom',
      buttons: [
        {
          text: '',
          side: 'end',
          icon: 'close',
          cssClass: 'cancelBTN',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();
  }
  async loader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        // duration: 5000,
        message: '<img src="assets/animation/loader2.svg" alt="loading...">',
        cssClass: 'custom_loading',
      })
      .then((a) => {
        a.present().then(() => {
          console.log('Loader Presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController
      .dismiss()
      .then(() => console.log('Loader dismissed'));
  }
  // checkBluetooth(){
  //   if(this.platform.is('android')){

  //   this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //     console.log('checking permission bluetooth',res)
  //     if(res.hasPermission){
  //       console.log('has bluetooth')
  //       return true
  //     }
  //     else{
  //       this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //         console.log('granted,',res)
  //       return true

  //       })
  //     }
  //   },err=>{
  //     this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //       console.log('granted,',res);
  //       return true

  //     })
  //   })
  // }
  // }
}
