import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { ServerService } from '../providers/server.service';
import { GlobalVariable } from '../global';
@Component({
  selector: 'app-add-new-email',
  templateUrl: './add-new-email.page.html',
  styleUrls: ['./add-new-email.page.scss'],
})
export class AddNewEmailPage implements OnInit {
  email: string = '';
  selectedType:any;
  emailData:any;
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private server:ServerService,
    private alertCtrl:AlertController,
    public global: GlobalVariable
  ) {
     this.emailData = this.navParams.get('data');
    console.log(this.emailData)
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  submit() {
    // Handle form submission
    console.log('Email submitted:', this.email,this.selectedType);
   this.addEmail();
  }
  ngOnInit() {
  }
  addEmail() {
    const emailData = {
      add_counter: '1',
      business_id: '76',
      report_type: this.emailData,
      email_0: this.email,
      type_0: this.selectedType,
      from: 'app'
    };

    this.server.addEmails(emailData).subscribe(
      response => {
        this.showAlert('Success', 'Emails added successfully');
        this.dismiss();
      },
      error => {
        this.showAlert('Error', 'Failed to add emails');
      }
    );
  }

 async showAlert(title: string, message: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: message,
      buttons: ['OK']
    });
    alert.present();
  }

}
