import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { GlobalVariable } from '../global';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  // httpOptions: { headers: HttpHeaders };
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };
  categoryId: any;
  constructor(private http: HttpClient, private globals: GlobalVariable) {}
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
  public AppLogin(loginData): Observable<any> {
    var data = JSON.stringify({
      username: loginData.username,
      password: loginData.password,
    });
    var link = this.globals.baseUrl + 'Business_controller/app_login';

    // console.log(data);

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  uploadImage(base64Image: string): Observable<any> {
   // const base64ImageContent = base64Image.split(',')[1];
    const url = this.globals.baseUrl + 'Staff_selection/uploadBase64Image'; 
    let payload = new FormData();
 payload.set('image',base64Image);
    return this.http.post(url, payload);
  }
  updatestaff()
  {
    const url= this.globals.baseUrl + 'staff_selection/updateStaff';
    console.log(this.globals.busniess_id,this.globals.id);
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
    first_name: this.globals.globalname,
    last_name:this.globals.last_name,
    nickname:this.globals.nick_name,
    street: this.globals.street,
    city: this.globals.city,
    state: this.globals.state,
    zip: this.globals.zip,
    phone: this.globals.phone,
    alternate_phone:this.globals.alternate_phone,
    email: this.globals.email,
    emergency_contact_phone:this.globals.emergency_contact_phone,
    emergency_contact_name: this.globals.emergency_contact_name,
    dob:this.globals.dob,
    citizen: this.globals.citizen,
    image: this.globals.image,
    description: this.globals.description,
    id : this.globals.id,
    description_id:this.globals.desc_id,
    profile_1:this.globals.file1,
    profile_2: this.globals.file2,
    profile_3:this.globals.file3,
    profile_4:this.globals.file4,
    profile_5:this.globals.file5,
    driving_license:this.globals.driving_license,
    social_security_number:this.globals.social_security_card,
    business_license1:this.globals.business_license1,
    business_license2:this.globals.business_license2,
    business_license3:this.globals.business_license3,
    password:this.globals.password
    });

    return this.http.post<any>(url, data).pipe(catchError(this.handleError));
  }
  getStaffList(){
    const url= this.globals.baseUrl + 'Staff_selection/getStaffList';
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      staff:false
    });
    return this.http.post<any>(url, data).pipe(catchError(this.handleError));
  }

  public getOrders(
    id,
    offset,
    order_type,
    limit?,
    start?,
    end?,
    platform_via?
  ): Observable<any> {
    let date;
    if (start && end) {
      date = `${start}-${end}`;
    }
    var data = JSON.stringify({
      id: id,
      usertype: this.globals.user_type,
      offset: offset,
      order_type: order_type,
      limit: limit,
      // filter_by_date: 'week',

      filter_date: date,
      platform: 'app',
      platform_via: platform_via,
    });
    var link = this.globals.baseUrl + 'Menu/get_orders';

    // console.log(data);

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public acceptOrder(orderId): Observable<any> {
    var data = JSON.stringify({
      o_id: orderId,
      usertype: this.globals.user_type,
    });
    var link = this.globals.baseUrl + 'Menu/accept_order';

    // console.log(data);

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public cancelOrder(orderId): Observable<any> {
    var data = JSON.stringify({
      o_id: orderId,
      usertype: this.globals.user_type,
    });
    var link = this.globals.baseUrl + 'Menu/cancel_order';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public readyOrder(orderId): Observable<any> {
    var data = JSON.stringify({ o_id: orderId, id: this.globals.busniess_id });
    var link = this.globals.baseUrl + 'Menu/ready_order';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public dispatchOrder(orderId): Observable<any> {
    var data = JSON.stringify({
      o_id: orderId,
      usertype: this.globals.user_type,
    });
    var link = this.globals.baseUrl + 'Menu/dispatch_order';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public go_ready_menu(menu_ready, action, date?): Observable<any> {
    if (!action) {
      var data = JSON.stringify({
        business_id: this.globals.rest_id,
        action: 'update',
        menu_ready: menu_ready,
        date: date,
      });
      // console.log(data);
    } else {
      var data = JSON.stringify({ business_id: this.globals.rest_id });
      // console.log(data);
    }
    var link = this.globals.baseUrl + 'Business_controller/business_ready_menu';

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public changeDeliveryTimings(type, time, timeType): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      type: type,
      time: time,
      timeType: timeType,
    });

    var link =
      this.globals.baseUrl +
      'business_controller/update_business_delivery_pickup_time';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public changePickupTimings(type, time, timeType): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      type: type,
      time: time,
      timeType: timeType,
    });
    var link =
      this.globals.baseUrl +
      'business_controller/update_business_delivery_pickup_time';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  public changeItemPreptime(orderid, datetime, time_s): Observable<any> {
    var data = JSON.stringify({
      order_id: orderid,
      new_scheduled_time: datetime,
      time_s: time_s,
      usertype: this.globals.user_type,
    });

    var link = this.globals.baseUrl + 'menu/update_order_business_app';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

 

  public search(key, type, to, from): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      keyword: key,
      time_from: from,
      time_to: to,
      type: type,
      platform: 'app',
    });
    var link = this.globals.baseUrl + 'search/get_orders';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  public getOutOfStockList(): Observable<any> {
    var data = JSON.stringify({ business_id: this.globals.busniess_id });
    var link = this.globals.baseUrl + 'menu/out_of_stock_items';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  public updateOutOfStockList(items): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      data: items,
    });
    var link = this.globals.baseUrl + 'menu/change_out_of_stock_status';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getReportsData(from, to): Observable<any> {
    // if(this.globals.posReports==true){

    // }
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      from: from,
      to: to,
      order_via: this.globals.posReports == true ? 'POS' : '',
    });

    var link = this.globals.baseUrl + 'business_controller/business_app_report';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public saveOneSignalData(id, username): Observable<any> {
    var data = JSON.stringify({ b_id: username, device_id: id });

    var link = this.globals.baseUrl + 'business_controller/business_player_id';
    // console.log('device id api ',data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getUpdatedOrders(): Observable<any> {
    var data = JSON.stringify({
      id: this.globals.busniess_id,
      usertype: this.globals.user_type,
    });
    var link = this.globals.baseUrl + 'Menu/get_update_business_orders';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getusers(id, name, email, phone): Observable<any> {
    var data = JSON.stringify({
      udid: id,
      business_id: this.globals.busniess_id,
      name: name,
      email: email,
      phone: phone,
    });
    var link = this.globals.baseUrl + 'customer_controller/get_search_customer';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public CheckPointsStatus(Username, Udid): Observable<any> {
    var data = JSON.stringify({
      username: Username,
      qr_code: Udid,
      usertype: this.globals.user_type,
    });
    var link = this.globals.baseUrl + 'business_controller/app_points_status';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public PointSystem(Username, Udid, points, RedeemFlag): Observable<any> {
    var data = JSON.stringify({
      qr_code: Udid,
      username: Username,
      points: points,
      redeem: RedeemFlag,
      usertype: this.globals.user_type,
    });
    var link = this.globals.baseUrl + 'business_controller/app_points_redeem';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public Punchcards(Udid): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      udid: Udid,
    });
    var link = this.globals.baseUrl + 'rewards/get_punches_rewards';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public addPunches(
    type,
    qr_code,
    redeem_status,
    punch_count,
    id
  ): Observable<any> {
    var data = JSON.stringify({
      type: type,
      business_id: this.globals.busniess_id,
      udid: qr_code,
      punches: punch_count,
      redeem: redeem_status,
      usertype: this.globals.user_type,
      item_id: id,
    });
    var link = this.globals.baseUrl + 'rewards/add_customer_punches';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public fetchRewards(udid): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      udid: udid,
    });
    var link = this.globals.baseUrl + 'rewards/get_reward_list';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public redeemMyRewards(udid, reward_id): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      udid: udid,
      reward_id: reward_id,
    });
    var link = this.globals.baseUrl + 'rewards/redeem_reward';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getGiftCards(udid?: any): Observable<any> {
    var data = JSON.stringify({ business_id: this.globals.busniess_id });
    var link = this.globals.baseUrl + 'giftcard/get_giftcard_designs';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  public getUsersGiftCards(udid?: any): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      udid: udid,
    });
    var link = this.globals.baseUrl + 'giftcard/get_customer_giftcards';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public redeemGiftCard(id, amount, udid): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      udid: udid,
      giftcard_id: id,
      amount: amount,
    });
    var link = this.globals.baseUrl + 'giftcard/redeem_giftcard';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public buyGiftCardByCash(
    id,
    udid_r,
    design_id,
    amount,
    message,
    udid
  ): Observable<any> {
    var data = JSON.stringify({
      udid_r: udid_r,
      business_id: this.globals.busniess_id,
      udid_s: udid,
      giftcard_id: '',
      design_id: design_id,
      amount: amount,
      message: message,
    });
    var link = this.globals.baseUrl + 'giftcard/buy_giftcard_cash';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public buyGiftCardByCreditCard(
    token,
    id,
    udid_r,
    design_id,
    amount,
    message,
    udid
  ): Observable<any> {
    var data = JSON.stringify({
      udid_r: udid_r,
      business_id: this.globals.busniess_id,
      udid_s: udid,
      token: token,
      giftcard_id: id,
      design_id: design_id,
      amount: amount,
      message: message,
    });
    var link = this.globals.baseUrl + 'giftcard/buy_giftcard';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getUserPoints(coordinates, udid): Observable<any> {
    var data = JSON.stringify({
      udid: udid,
      coordinates: coordinates,
      business_username: this.globals.username,
    });
    var link = this.globals.baseUrl + 'Customer_controller/get_points';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public getpoints_menuItems(): Observable<any> {
    var data = JSON.stringify({ business_id: this.globals.busniess_id });
    var link = this.globals.baseUrl + 'rewards/get_point_rewards';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  public redeemPoints_menuItems(id, type, udid): Observable<any> {
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      udid: udid,
      item_id: id,
      reward_type: type,
    });
    var link = this.globals.baseUrl + 'rewards/get_redeem_points';
    // console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  public getSuperCategories(platform) {
    let link = this.globals.baseUrl + 'menu/app_categories_new';
    let data = JSON.stringify({
      business_id: this.globals.busniess_id,
      platform: platform,
    });

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }

  adjustment(type, udid, order_id, amount, tax, discount, action, adjustment) {
    console.log(
      'type ',
      type,
      udid,
      order_id,
      'amount',
      amount,
      tax,
      discount,
      action,
      adjustment
    );
    // var data = {  type:type, udid:udid, adjustment:adjustment, amount:amount, order_id: order_id,tax:tax, discount:discount, action:action, delivery:delivery};
    let body = new FormData();

    body.set('type', type);
    body.set('udid', udid);
    body.set('adjustment', adjustment);
    body.set('order_id', order_id);
    body.set('tax', tax);
    body.set('amount', amount);
    body.set('discount', discount);
    body.set('action', action);
    body.set('processing_fee', '0');
    body.set('tipCheck', 'false');

    console.log('Adjustment Server -> ', body);
    var link = this.globals.baseUrl + 'Menu/adjust_payment';
    return this.http.post<any>(link, body).pipe(catchError(this.handleError));
  }

  public deliverAll(business_id, finalize_all?, type?) {
    var link = this.globals.baseUrl + 'menu/dispatch_order';
    var data = {
      business_id: business_id,
      finalize_all: finalize_all,
      status: type,
    };

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  item_out_of_stock(item_id, in_stock?) {
    var link = this.globals.baseUrl + 'menu/update_item_in_stock';
    var data = JSON.stringify({
      business_id: this.globals.busniess_id,
      item_id: item_id,
      in_stock: in_stock,
    });

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  checkItemsInStock(orderid) {
    var data = JSON.stringify({
      o_id: orderid,
      business_id: this.globals.busniess_id,
    });
    var link = this.globals.baseUrl + 'menu/check_item_in_stock_status';

    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getBusinessBranches(): Observable<any> {
    let path =
      this.globals.baseUrl + 'Business_controller/getBusinessBranchesDetails';
    let data = JSON.stringify({
      business_id: this.globals.busniess_id,
      // business_id: this.globals.busniess_id,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMessage(startDate, endDate): Observable<any> {
    let path =
      this.globals.messageBaseUrl +
      '/online_ordering/public/index.php/api/filterMessages?to=' +
      this.globals.phoneNumber +
      '&start_time=' +
      startDate +
      '&end_time=' +
      endDate;
    return this.http
      .get<any>(path)
      .pipe(retry(1), catchError(this.handleError));
  }
  pushToChowly(): Observable<any> {
    let path = this.globals.baseUrl + 'chowly_controller/update_menu';
    let data = JSON.stringify({
      business_id: this.globals.busniess_id,
      platform: 'app',
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  getItems(categoryId): Observable<any> {
    let path = this.globals.baseUrl + 'menu/app_category_items';
    let data = JSON.stringify({
      business_id: this.globals.busniess_id,
      category_id: categoryId,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  
  public getbusinessreports(business_id, report_type, limit, offset){
    const body = {
      business_id,
      report_type,
      limit,
      offset
    };
    var link = this.globals.baseUrl + 'reporting/getBusinessreports';
    return this.http.post<any>(link, body).pipe(catchError(this.handleError));
  }
  public getbusinessreportsemail(business_id, report_type, limit, offset){
    const body = {
      business_id,
      report_type,
      limit,
      offset
    };
    var link = this.globals.baseUrl + 'reporting/getBusinessreportEmails';
    return this.http.post<any>(link, body).pipe(catchError(this.handleError));
  }
  deleteemail(id){
    const formData = new FormData();
    formData.append('id', id);

    // Set headers if required (e.g., content-type)
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });

    // Perform the POST request
    var link = this.globals.baseUrl + 'business_controller/delete_report_emails';
    return this.http.post(link, formData, { headers });
  }
  updateEmail(businessId: string, email: string, type: string, id: string, from: string): Observable<any> {
    const body = new FormData();
    body.append('business_id', businessId);
    body.append('email', email);
    body.append('type', type);
    body.append('id', id);
    body.append('from', from);

    const headers = new HttpHeaders({
      // Add any headers if required
    });
    var link = this.globals.baseUrl + 'business_controller/update_report_emails';
    return this.http.post(link, body, { headers });
  }
  addEmails(data: any): Observable<any> {
    

    // Prepare the form data
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    var link = this.globals.baseUrl + 'business_controller/add_report_emails';
    // Send POST request
    return this.http.post(link, formData);
  }
  updateTime(data:any){
    console.log(data);
    // const formData = new FormData();
    // for (const key in data) {
    //   if (data.hasOwnProperty(key)) {
    //     formData.append(key, data[key]);
    //   }
    // }
    const jsonData = JSON.stringify(data);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });
  
    var link = this.globals.baseUrl + 'reporting/updateTime';
    // Send POST request
    return this.http.post(link, jsonData, { headers: headers });

  }
  generateReport(businessId: string, date: string,cat_id,supercat_id,type): Observable<any> {
    const formData = new FormData();
    formData.append('business_id', businessId);
    formData.append('date', date);
    formData.append('super_category', supercat_id); // Optional, disabled in your example
    formData.append('category', cat_id); // Optional, disabled in your example
    formData.append('type', type);
    formData.append('pdf_file', 'true');
    formData.append('from', 'app'); // Disabled in your example
    formData.append('html', 'false'); // Disabled in your example
    var link = this.globals.baseUrl + 'business_controller/generate_consolidate_report_from_day_close';
    return this.http.post<any>(link, formData);
  }
  generatesummaryReport(businessId: string, date: string,cat_id,supercat_id): Observable<any> {
    const formData = new FormData();
    formData.append('business_id', businessId);
    formData.append('date', date);
    formData.append('super_category', supercat_id); // Optional, disabled in your example
    formData.append('category', cat_id); // Optional, disabled in your example
    var link = this.globals.baseUrl + 'business_controller/generate_category_summary_report';
    return this.http.post<any>(link, formData);
  }
  gratuity_report(id,date_:string){
    const body = new FormData();
    body.append('business_id',id);
    body.append('date', date_);
    var link = this.globals.baseUrl + 'business_controller/generate_tip_by_staff_report';
    return this.http.post<any>(link, body);
  }
  getsuper_category(id){
    const formData = new FormData();
    formData.append('business_id', id);
    var link = this.globals.baseUrl + 'business_controller/getSuperCategories';
    return this.http.post<any>(link, formData);
  }
  get_category(id,b_id){
    const formData = new FormData();
    formData.append('id', id);
    formData.append('business_id', b_id);
    var link = this.globals.baseUrl + 'business_controller/get_all_categories';
    return this.http.post<any>(link, formData);
  }
  getTimesheet(businessId: any, week: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      business_id: businessId,
      week: week
    };
    var link = this.globals.baseUrl + 'timesheet/getTimesheet';
    return this.http.post<any>(link, body, { headers: headers });
  }
  getTimesheetdetail(businessId: any, week: string, staffid): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      business_id: businessId,
      week: week,
      staff_id:staffid
    };
    var link = this.globals.baseUrl + 'timesheet/getStaffDetailTimesheet';
    return this.http.post<any>(link, body, { headers: headers });
  }
  generateTimesheet(userid,business_id,type,weekstart,weekend,from){
    
    var link = this.globals.baseUrl + 'timesheet/generateTimesheet';
    let params = new HttpParams()
      .set('user_ids', userid)
      .set('business_id', business_id)
      .set('type', type)
      .set('week_start', weekstart)
      .set('week_end', weekend)
      .set('from', from);

    return this.http.get(link, { params });
  }
  generateTimesheetsingle(userid,business_id,type,weekstart,weekend,from){
    
    var link = this.globals.baseUrl + 'timesheet/generateTimesheetsingle';
    let params = new HttpParams()
      .set('user_ids', userid)
      .set('business_id', business_id)
      .set('type', type)
      .set('week_start', weekstart)
      .set('week_end', weekend)
      .set('from', from);

    return this.http.get(link, { params });
  }
  addtime(id,b_id,intime,outtime,date){
    var link = this.globals.baseUrl + 'business_controller/add_user_time_manual';
    const formData = new FormData();
    formData.append('staff_id', id);
    formData.append('business_id',b_id);
    formData.append('clock_in',intime);
    formData.append('clock_out',outtime);
    formData.append('date',date);
    formData.append('from','app');
    return this.http.post<any>(link, formData);
  }
  edittime(id,b_id,intime,outtime){
    var link = this.globals.baseUrl + 'business_controller/update_user_time_manual';
    const formData = new FormData();
    formData.append('id', id);
    formData.append('business_id',b_id);
    formData.append('in_time',intime);
    formData.append('out_time',outtime);
    return this.http.post<any>(link, formData);
  }
  deltime(id){
    var link = this.globals.baseUrl + 'business_controller/delete_staff_time_record';
    const formData = new FormData();
    formData.append('id', id);
    return this.http.post<any>(link, formData);
  }
}
